import React from "react"
import Colors from "../../constants/Colors"
import { Activity } from "../../models/NationalPark"
import CustomCard from "../cards/CustomCard"
import { IoPeopleCircleOutline } from "react-icons/io5"
import { AiFillEuroCircle } from "react-icons/ai"
import { FiGrid } from "react-icons/fi"

interface Props {
  title: string
  activity: Activity
}

export default function ActivitySidebar({ title, activity }: Props) {
  return (
    <div className="col-span-12 md:col-span-4 pr-6">
      <CustomCard className="pr-1 pl-1 pt-0 mt-0 md:mr-6 mr-2 ml-0 md:ml-0">
        <h4 className="mt-0 mb-3">{title}</h4>
        {activity.activity_price !== null &&
          activity.activity_price !== undefined && (
            <div className="bg-lightest rounded-sm pt-2 pb-1 px-2">
              <p className="inline-block mt-0 mb-0 align-text-bottom text-sm">
                Price
              </p>
              <p className="inline-block mt-0 mb-0 align-text-bottom text-sm float-right w-32 font-bold">
                <AiFillEuroCircle
                  size={14}
                  className="inline-block"
                  color={Colors.themeColor}
                  style={{ marginBottom: 2, marginRight: 5 }}
                />
                {activity.activity_price[0].text}
              </p>
            </div>
          )}
        {activity.activity_type !== null &&
          activity.activity_type !== undefined && (
            <div className="bg-white rounded-sm pt-2 pb-1 px-2">
              <p className="inline-block mt-0 mb-0 align-text-bottom text-sm">
                Type
              </p>
              <p className="inline-block mt-0 mb-0 align-text-bottom text-sm float-right w-32 font-bold">
                <FiGrid
                  size={14}
                  className="inline-block"
                  color={Colors.themeColor}
                  style={{ marginBottom: 2, marginRight: 5 }}
                />
                {activity.activity_type}
              </p>
            </div>
          )}
        {activity.activity_suitable_for !== null &&
          activity.activity_suitable_for !== undefined && (
            <div className="bg-lightest rounded-sm pt-2 pb-1 px-2">
              <p className="inline-block mt-0 mb-0 align-text-bottom text-sm">
                Suitable for
              </p>
              <p className="inline-block mt-0 mb-0 align-text-bottom text-sm float-right w-32 font-bold">
                <IoPeopleCircleOutline
                  size={14}
                  className="inline-block"
                  color={Colors.themeColor}
                  style={{ marginBottom: 2, marginRight: 5 }}
                />
                {activity.activity_suitable_for}
              </p>
            </div>
          )}
      </CustomCard>
    </div>
  )
}
